import React from 'react';
import PropTypes from 'prop-types';
import BioCard from '../components/BioCard';
import '../css/slick.css';
import Slider from 'react-slick';

class BioSlider extends React.Component {
  constructor({props}) {
    super(props);
  }
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings} className="pl5-l pr5-l mb6">
        {this.props.input.map(bioItem => (
          <BioCard input={bioItem.node} classNames="pa3" />
        ))}
      </Slider>
    );
  }
}

export default BioSlider;

BioSlider.propTypes = {
  input: PropTypes.object.isRequired
};
