import React from 'react';
import PropTypes from 'prop-types';

const OneColText = ({text, classNames}) => (
  <div className={`pt4 pb4-l pl3 pr3 relative ${classNames}`}>
    <div className="measure-widest center lh-copy f4 pl6-l pr6-l" dangerouslySetInnerHTML={{__html: text}} />
  </div>
);
export default OneColText;

OneColText.propTypes = {
  input: PropTypes.object.isRequired
};
