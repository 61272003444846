import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import OneColText from '../components/OneColText';
import Quote from '../components/Quote';
import BioHeader from '../singletonBlocks/BioHeader';
import BioSlider from '../components/BioSlider';

const Bio = ({data: {prismicStaffBio, bios}, location}) => {
  const {data} = prismicStaffBio;
  return (
    <Layout customSEO>
      <SEO title={`${data.name.text}`} keywords={['Low Doc Loans', 'Development Finance', 'Construction Finance', 'Bridging Finance', 'Non Bank Loans', 'New Build Finance']} />
      <div className="header-spacer" />
      <BioHeader title={data.name.text} image={data.bio_image} video={data.video_id} position={data.position} phone={data.phone_number} email={data.email} />
      <OneColText text={data.bio.html} classNames="mt5-l mt3 mb5" />
      {data.quote.text && <Quote quote={data.quote} />}
      <h2 className="blue f2 f1-l center tc mt5 mt6-l mb5">Our Team</h2>
      <BioSlider input={bios.edges} />
    </Layout>
  );
};

export default Bio;

Bio.propTypes = {
  location: PropTypes.object.isRequired
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query BioBySlug($uid: String!) {
    prismicStaffBio(uid: {eq: $uid}) {
      uid
      data {
        name {
          text
        }
        position {
          text
        }
        bio_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        phone_number {
          text
        }
        email {
          text
        }
        video_id {
          text
        }
        quote {
          text
        }
        bio {
          html
        }
      }
    }
    bios: allPrismicStaffBio(filter: {uid: {ne: $uid}}, sort: {fields: [data___weight], order: DESC}) {
      edges {
        node {
          id
          uid
          data {
            name {
              text
            }
            position {
              text
            }
            bio_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            portal_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            weight
          }
        }
      }
    }
  }
`;
