import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import ModalVideo from 'react-modal-video';
import '../css/video-modal.css';

class BioHeader extends React.Component {
  constructor({props}) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({isOpen: true});
  }
  render() {
    return (
      <div className="pl3 pr3 pl5-l pr0-l flex-l flex-row-reverse-l items-center relative">
        <Img fluid={this.props.image.localFile.childImageSharp.fluid} className="w-100 h-auto overflow-hidden relative w-60-l" />
        <div className="w-100 w-40-l pr6-l">
          <h2 className="blue f1-l f2 lh-copy mb2 mb4-l">{this.props.title}</h2>
          <div className="blue f3-l f5 medium mb4">{this.props.position.text}</div>
          <p className="f5">
            <strong className="blue">T</strong> {this.props.phone.text}
          </p>
          <p className="f5">
            <strong className="blue">E</strong>{' '}
            <a href={`mailto:${this.props.email.text}`} className="link black">
              {this.props.email.text}
            </a>
          </p>
          {this.props.video.text && (
            <div onClick={this.openModal} className="button mt3 dib">
              Watch Video
            </div>
          )}
        </div>

        {this.props.video.text && <ModalVideo channel="youtube" isOpen={this.state.isOpen} videoId={this.props.video.text} onClose={() => this.setState({isOpen: false})} autoplay="1" />}
      </div>
    );
  }
}

export default BioHeader;

BioHeader.propTypes = {
  input: PropTypes.object.isRequired
};
