import React from 'react';
import PropTypes from 'prop-types';

const Quote = ({quote}) => (
  <div className="pt6 pb6 pl4 pr4 tc relative bg-blue mt5 mt0-l">
    <div dangerouslySetInnerHTML={{__html: quote.text}} className="f1-l f2 lh-copy measure-wide center" />
  </div>
);

export default Quote;

Quote.propTypes = {
  input: PropTypes.object.isRequired
};
