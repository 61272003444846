import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const BioCard = ({ input, classNames }) => {
  console.log(input);
  return (
    <div className={`service-card ${classNames}`}>
      <Link to={input.uid}>
        <Img fluid={input.data.portal_image.localFile.childImageSharp.fluid} className="w-100 h-auto" />
      </Link>
      <div className="f2 blue medium lh-copy mt4 mb2">{input.data.name.text}</div>
      <div className="blue f5 medium">{input.data.position.text}</div>
      {input?.data?.phone_number?.text && <p className="f5">
        <strong className="blue">T</strong> {input?.data?.phone_number?.text}
      </p>}
      <Link to={input.uid} className="button mt4">
        Read More
      </Link>
    </div>
  );
}
export default BioCard;

BioCard.propTypes = {
  input: PropTypes.object.isRequired
};
